import React from "react"
import PrescriptionStore from "../PrescriptionStore"
import axios from "axios"
import {observer} from "mobx-react"
import MesureStore from './MesureStore'
import {HashRouter as Router} from "react-router-dom"

import NavisPopup from './mesure_components/NavisPopup'
import NewDataPopup from "./mesure_components/NewDataPopup";
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import ConnecteurV3 from "../../BridgeV2/ConnecteurV3";
import {Role} from "../../Shared/Enums/Scope";
import {IS_OPTICIAN, JOB} from "../../Shared/Helper";
import Tooltip from "../../Shared/Tootip";
import Loader from "../../Shared/Loader";
import ActSelection from "./mesure_components/ActSelection";
import PatientOverview from "../../patient_data/PatientOverview";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {isLocked} from "../AppointmentValidation";
import {ActNature, RouteurState} from "../../Shared/Enums/Enums";
import AppointmentCheck from "../../Orthoptistes/Appointment/Check/AppointmentCheck";
import AppointmentSideBar from "./mesure_components/SideBar/AppointmentSideBar";
import AppointmentAlert from "../../Orthoptistes/Appointment/AppointmentAlert";

import WebToDesktop from "../../WebToDesktop/WebToDesktop";
import ImportLastAppointmentModal from "./mesure_components/ImportLastAppointmentModal";
import BrandedElement from "../../Shared/BrandedElement";
import AppointmentRoutes from "./AppointmentRoutes";
import Preferences from "../../Shared/Preferences/Preferences";
import {defaultShortcuts} from "../../Shared/Preferences/hooks/useShortcuts";
import CVStore from "../../CarteVitale/CVStore";

let wtd = new WebToDesktop();
wtd.initialize_shorcuts(MesureStore)
global.wtd = WebToDesktop
Bugsnag.start({
    apiKey: '73681d156acb97dde1f524e76fbbb41e',
    plugins: [new BugsnagPluginReact()],
    appType: 'client',
    autoTrackSessions: false,
    onError: function (event) {
        axios.post("/api/v3/logs/errors/front_measures", {error: event})
            .then((resp) => {
            })
            .catch((error) => {
            })
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const ErrorView = () => {
    return (
        <div className="container">
            <h1 className="center">Une erreur est survenue</h1>
            <p className="center">Une erreur grave est survenue, l'erreur est remontée au support.</p>
        </div>
    )
}

@observer
class MesureRouter extends React.Component {
    constructor(props) {
        super(props);
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        this.incrementFondoeuilKey = this.incrementFondoeuilKey.bind(this)
        this.checkCookie = this.checkCookie.bind(this)
        this.state = {
            fondoeuil_key: 1,
            state: RouteurState.LOADING,
            chains: []
        }
        this.checkCookieInterval = setInterval(this.checkCookie, 5000)
    }

    componentDidMount() {
        axios.get(`/mesure/check/${this.props.appointement.id}`).then().catch((error) => {
            window.location.reload()
        })

        this.getTextualShortcuts()

        MesureStore.patient_id = this.props.patient.id
        MesureStore.patient = this.props.patient
        MesureStore.appointement_id = this.props.appointement.id
        MesureStore.typesdemesures = this.props.typesdemesures
        MesureStore.stereoscopy = this.props.appointement.stereoscopy
        MesureStore.appointment_teletransmission_status = this.props.appointement.teletransmission_status
        MesureStore.appointment_date = Helper.formatDate(this.props.appointement.start)
        MesureStore.orthoptiste = {...this.props.orthoptiste, orthoptic_acts_enabled: this.props.orthoptic_acts_enabled}
        MesureStore.cabinet = this.props.cabinet
        MesureStore.selected_act = this.props.acte ?? undefined
        MesureStore.last_appointements = this.props.last_appointements
        MesureStore.loadKindsMesures()
        MesureStore.hasImages()
        MesureStore.signalBridges(this.props.appointement.id)
        MesureStore.actSaved(this.props.acte != undefined)
        MesureStore.report = this.props.appointement.report
        MesureStore.anamnesis = this.props.appointement.comment
        MesureStore.comment_for_ophtalmologist = this.props.appointement.comment_for_ophtalmologist
        MesureStore.orthoptic_text = this.props.appointement.orthoptic_text
        MesureStore.private_comment = this.props.appointement.private_comment
        MesureStore.constraints_bypassed = !!this.props.appointement.bypass_reason
        MesureStore.isAppointmentComplete = this.props.appointement.status_controle === 1
        MesureStore.pathologies = this.props.pathologies
        MesureStore.metadata = {
            orthoptist_firstname: this.props.orthoptiste.prenom,
            orthoptist_lastname: this.props.orthoptiste.nom,
            orthoptist_address: this.props.cabinet.address,
            orthoptist_city: this.props.cabinet.city,
            orthoptist_postal_code: this.props.cabinet.postal_code,
            orthoptist_phone: this.props.orthoptiste.phone_number,
            orthoptist_email: this.props.orthoptiste.email,
            orthoptist_office_name: this.props.cabinet.name,
            orthoptist_adeli: this.props.orthoptiste.numero_facturation
        }
        if (MesureStore.isAppointmentComplete) {
            MesureStore.setStatus(false)
        }
        MesureStore.media = this.props.media
        MesureStore.getAttachedPrescription()

        PrescriptionStore.loadIfExist(this.props.appointement.id)
        PrescriptionStore.level = 0

        CVStore.getPrescriptors()

        var that = this
        if (this.props.refractometre != undefined) {
            App.messages = App.cable.subscriptions.create({
                channel: 'RefractometreChannel',
                id: that.props.refractometre.id
            }, {
                received: function (data) {
                    let id = parseInt(data.data["id"])
                    if (id == that.props.refractometre["id"]) {
                        //nouvelles reception
                        if (confirm("Réception de données du refractometre (" + id + ") , accepté ?")) {
                            axios.post("/api/refractometre/saveData", {
                                data: data.data,
                                appointment_id: that.props.appointement.id
                            }).then((data) => {
                                document.location.reload(true);
                            })
                        }
                    }
                }
            });
            global.messages = App.messages
        }
        this.setRouteurState()
    }

    getTextualShortcuts = () => {
        Preferences.get('textual_shortcuts').then(shortcuts => {
            MesureStore.textual_shortcuts = [...(JSON.parse(shortcuts ?? "[]")), ...defaultShortcuts] ?? []
        })
    }

    checkCookie() {
        axios.get(`/mesure/check/${this.props.appointement.id}`).then().catch((error) => {
            if (!error.response?.data?.appointment_id) return
            window.location.href = `/mesure/${error.response.data.appointment_id}`
        })
    }


    setRouteurState() {
        if (IS_OPTICIAN) {
            if (this.props.acte !== undefined && this.props.acte !== null) {
                this.setState({state: RouteurState.IN_APPOINTMENT})
                return
            }
            axios.patch("/api/appointment/act/first").then((resp) => {
                MesureStore.selected_act = resp.data
                this.setState({state: RouteurState.IN_APPOINTMENT})
            })
            return
        }
        this.chainedActAvailable()
        if (this.props.acte === undefined || this.props.acte === null) {
            this.setState({state: RouteurState.LOADING})
        } else {
            if (!this.props.acte.chainable) {
                this.setState({state: RouteurState.IN_APPOINTMENT})
                CVStore.initPrescriptors()
                return
            }
            axios.get("/orthoptistes/chained_acts", {
                params: {
                    act_id: this.props.acte.id,
                    patient_id: this.props.patient.id
                }
            }).then((resp) => {
                MesureStore.selectedChain = resp.data[0]
                CVStore.initPrescriptors()
                this.setState({state: RouteurState.IN_APPOINTMENT})
            })
        }
    }

    chainedActAvailable() {
        axios.get("/orthoptistes/chained_acts", {params: {patient_id: this.props.patient.id}}).then((resp) => {
            if (resp.data.length > 0) MesureStore.chains = resp.data
            if (!MesureStore.selected_act) {
                this.setState({state: resp.data.length > 0 ? RouteurState.TYPE_SELECTION : RouteurState.ACT_SELECTION})
            }
            // else this.setState({state: RouteurState.IN_APPOINTMENT})
            // if (resp.data.length > 0) this.setState({chains: resp.data}, () => this.setState({state: RouteurState.TYPE_SELECTION}))
            // else this.setState({state: RouteurState.ACT_SELECTION})
        })
    }

    componentWillUnmount() {
        if (App.messages != undefined) {
            App.messages.unsubscribe()
        }
        clearInterval(this.checkCookieInterval)

    }

    isApptCanceled() {
        return MesureStore.selected_act.prix === 0 && MesureStore.selected_act.nature === ActNature.TELEMEDICAL.name
    }

    lock() {
        if (!this.isApptCanceled() && (!MesureStore.completed || Cookies.get()["last_appointement_done"] === "false")) {
            Cookies.set('last_appointements_data_done', false)

            return null
            return (
                <Tooltip id="exam_lock" text={MesureStore.lockedElementToString()} position="left">
                    <div style={{
                        position: "absolute",
                        left: "0",
                        right: JOB === Role.ORTHOPTIST ? "calc(100vw - 290px)" : "calc(100vw - 170px)",
                        top: "0",
                        height: "90px",
                        background: "rgba(0,0,0,0.8)"
                    }}>
                        <h1 className="center white-text"><i className="material-icons">lock</i></h1>
                    </div>
                </Tooltip>
            )
        } else {
            Cookies.set('last_appointements_data_done', true)
        }
    }

    incrementFondoeuilKey() {
        this.setState({fondoeuil_key: this.state.fondoeuil_key + 1})
    }

    needAlert = (required) => {
        console.log("required", required)
        return isLocked(...required)
    }

    renderAppt = () => {
        return <>
            {/*<ErrorBoundary>*/}
            <ConnecteurV3
                id={this.props.orthoptiste.id}
                bridge_uid={this.props.bridge_uid}
            >
            </ConnecteurV3>
            {MesureStore.is_appointment_new ? <BrandedElement temeoo>
                    <ImportLastAppointmentModal last_appointment={this.props.last_appointements[0]}/>
                </BrandedElement>
                : null}
            <Router>
                <NavisPopup
                    orthoptiste={this.props.orthoptiste}
                    appointement={this.props.appointement}
                    updateFO={this.incrementFondoeuilKey}
                />
                <NewDataPopup
                    orthoptiste={this.props.orthoptiste}
                    appointement={this.props.appointement}
                />
                {this.lock()}
                <AppointmentAlert/>
                <AppointmentRoutes
                    fundus_key={this.state.fondoeuil_key}
                    orthoptist={this.props.orthoptiste}
                    act_nature={MesureStore.selected_act.nature}
                />
            </Router>
            {/*</ErrorBoundary>*/}
        </>
    }

    renderRouteur() {
        switch (this.state.state) {
            case RouteurState.LOADING:
                return <div id="full-height"
                            style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Loader wide/>
                </div>
            case RouteurState.IN_APPOINTMENT:
                return this.renderAppt()
            case RouteurState.TYPE_SELECTION:
                MesureStore.is_appointment_new = true
                return <div style={{overflowY: "scroll"}}>
                    <div className="container">
                        {MesureStore.chains.length > 0 ? (
                            <ActSelection acts={this.props.actepossibles} chains={MesureStore.chains}
                                          patient={MesureStore.patient}
                                          changeState={(state) => this.setState({state: state})}/>
                        ) : (<div>PAS DE CHAINE</div>)}
                    </div>
                </div>
            case RouteurState.ACT_SELECTION:
                MesureStore.is_appointment_new = true
                return <div style={{overflowY: "scroll"}}>
                    <ActSelection acts={this.props.actepossibles}
                                  patient={MesureStore.patient}
                                  changeState={(state) => this.setState({state: state})}/>
                </div>
            case RouteurState.CHECK:
                return <AppointmentCheck
                    appointment_id={MesureStore.appointement_id}
                    patient={MesureStore.patient}
                    act={MesureStore.selected_act}
                    media={MesureStore.media}
                    details={{
                        anamnesis: MesureStore.anamnesis,
                        orthoptic_text: MesureStore.orthoptic_text,
                        comment_for_ophtalmologist: MesureStore.comment_for_ophtalmologist,
                        report: MesureStore.report,
                        pathologies: MesureStore.pathologies,
                        diabetes_questions: MesureStore.diabetes_questions
                    }}
                    measures={MesureStore.existingMesures.map(measure => {
                        return {...measure, name: measure.titre}
                    })}
                    prescriptions={Array.from(PrescriptionStore.prescriptions).map((prescription, index) => {
                        return {text: prescription[1], id: index}
                    })}
                />
            default:
                this.renderAppt()
        }
    }

    render() {
        return <ErrorBoundary FallbackComponent={ErrorView}>
            <div style={{display: "flex", height: "100vh"}}>
                <div style={{flex: "1 1 0", display: "flex", flexDirection: "column"}}>
                    <PatientOverview state={this.state.state}
                                     onCheck={() => this.setState({state: RouteurState.CHECK})}
                                     onBack={() => this.setState({state: RouteurState.IN_APPOINTMENT})}
                    />
                    {this.renderRouteur()}
                </div>
                {this.state.state === RouteurState.IN_APPOINTMENT ? <AppointmentSideBar/> : null}
            </div>
        </ErrorBoundary>
    }
}

export default MesureRouter
